<template>
  <div class="main">
    <div class="search">
      <span style="font-size: 14px;font-weight: bold;color: #fff9ff">사이트 설정</span>
    </div>
    <div class="data">
      <el-collapse v-model="activeNames" accordion style="width: 100%;">

        <el-collapse-item title=" ➽ 서버상태 설정" name="서버상태 설정">
          <div class="sitem">
            <el-select size="mini" style="width: 130px;" v-model="siteConfig.maintaining"
                       placeholder="서버상태">
              <el-option :value="managerConst.ENABLE" label="정상">정상
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="점검중">점검중
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item title=" ➽ 카지노,슬롯,Revolution홀덤 설정" name="카지노,Revolution홀덤  설정">
          <div class="sitem">
            <el-select size="mini" style="width: 180px;"
                       v-model="siteConfig.casinomaintaining"
                       placeholder="카지노">
              <el-option :value="managerConst.ENABLE" label="카지노 이용가능">카지노 이용가능
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="카지노 중지">카지노 중지
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <el-select size="mini" style="width: 180px;"
                       v-model="siteConfig.slotmaintaining"
                       placeholder="슬롯">
              <el-option :value="managerConst.ENABLE" label="슬롯 이용가능">슬롯 이용가능
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="슬롯 중지">슬롯 중지
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

          <div class="sitem">
            <el-select size="mini" style="width: 180px;"
                       v-model="siteConfig.revoholdemable"
                       placeholder="홀덤">
              <el-option :value="managerConst.ENABLE" label="홀덤 이용가능">홀덤 이용가능
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="홀덤 중지">홀덤 중지
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item title=" ➽ 계좌자동답변 설정" name="계좌자동답변 설정">
          <div class="sitem">
            <label style="width: 130px">실계좌 자동답변</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.bank1able"
                       placeholder="실계좌">
              <el-option :value="managerConst.ENABLE" label="자동">자동
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="수동">수동
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">가상계좌 자동답변</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.bank2able"
                       placeholder="가상계좌">
              <el-option :value="managerConst.ENABLE" label="자동">자동
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="수동">수동
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item title=" ➽ 고객센터,코인연동,게시글,특수베팅 설정" name="고객센터,코인연동,게시글,특수베팅 설정">
          <div class="sitem">
            <label style="width: 130px">고객센터 점검설정</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.customercenterable"
                       placeholder="고객센터점검설정">
              <el-option :value="managerConst.ENABLE" label="정상">정상
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="점검중">점검중
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">코인연동 설정</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.coinsiteable"
                       placeholder="코인연동">
              <el-option :value="managerConst.ENABLE" label="연동하기">연동하기
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="아니오">아니오
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">특수베팅 알림설정</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.riskBetAlarmable"
                       placeholder="가상계좌">
              <el-option :value="managerConst.ENABLE" label="알림">알림
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="아니오">아니오
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">게시글 알림설정</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.boardAlarm"
                       placeholder="가상계좌">
              <el-option :value="managerConst.ENABLE" label="알림">알림
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="아니오">아니오
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">게시글 클릭수 자동</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.autoAddBoardClicks"
                       placeholder="가상계좌">
              <el-option :value="managerConst.ENABLE" label="자동">자동추가
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="아니오">아니오
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item title=" ➽ 입출금 설정" name="입출금 설정">
          <div class="sitem">
            <label style="width: 130px">1회당 최소 입금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.rechargeMinLimit"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">1회당 최소 출금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.extractMinLimitOnce"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <!--                    <div class="sitem">-->
          <!--                        <label style="width: 130px">1회당 최대 출금</label>-->
          <!--                        <el-input size="mini" style="width: 100px" v-model="siteConfig.extractMaxLimitOnce"></el-input> 원-->
          <!--                        <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트-->
          <!--                        </el-button>-->
          <!--                    </div>-->
          <!--                    <div class="sitem">-->
          <!--                        <label style="width: 130px">1`일` 최대 출금</label>-->
          <!--                        <el-input size="mini" style="width: 100px" v-model="siteConfig.extractMaxLimit1day"></el-input> 원-->
          <!--                        <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트-->
          <!--                        </el-button>-->
          <!--                    </div>-->
          <div class="sitem">
            <label style="width: 130px">1`일` 최대 베팅금액</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.betCashMaxLimit1day"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">출금딜레이</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.exchangeDelay"></el-input>
            `분`
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
            <span style="display: inline-block;margin-left: 10px;color: #ff4d4d">마지막으로 출금에 성공한 시간 기준입니다</span>
          </div>

          <div class="sitem">
            <label style="width: 130px">자동입금확인 딜레이</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.autorechargetime"></el-input>
            분
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
            <span style="display: inline-block;margin-left: 10px;color: #ff4d4d">60분 이상 설정시 기능취소 할수 있습니다</span>
          </div>

          <div class="sitem">
            <label style="width: 130px">입금점검 상태</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.rechargeable"
                       placeholder="입금점검 상태">
              <el-option :value="managerConst.ENABLE" label="정상">정상
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="점검중">점검중
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

          <div class="sitem">
            <label style="width: 130px">출금점검 상태</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.exchangeable"
                       placeholder="출금점검 상태">
              <el-option :value="managerConst.ENABLE" label="정상">정상
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="점검중">점검중
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

          <div class="sitem">
            <label style="width: 130px">첫충지급(환전있을경우)</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.rechargebonusfirst"
                       placeholder="첫충지급(환전있을경우)">
              <el-option :value="managerConst.ENABLE" label="예,지급">예,지급
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="아니오">아니오
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">매충지급(환전있을경우)</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.rechargebonusday"
                       placeholder="매충지급(환전있을경우)">
              <el-option :value="managerConst.ENABLE" label="예,지급">예,지급
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="아니오">아니오
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">입금 제한시간대설정</label>
            <el-input size="mini" style="width: 300px" v-model="siteConfig.rechargedenytime"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
            <label style="width: 230px"> <span style="color: red">(23:30~00:30,16:30~17:20,22:30~22:50)</span></label>
          </div>
          <div class="sitem">
            <label style="width: 130px">출금 제한시간대설정</label>
            <el-input size="mini" style="width: 300px" v-model="siteConfig.exchangedenytime"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
            <label style="width: 230px"> <span style="color: red">(23:30~00:30,16:30~17:20,22:30~22:50)</span></label>
          </div>


        </el-collapse-item>
        <el-collapse-item title=" ➽ 베팅관련 설정" name="베팅관련 설정">
          <div class="sitem">
            <label style="width: 150px">인플레이 베팅딜레이</label>
            <el-input size="mini" style="width: 200px" v-model="siteConfig.inplaybetdelay"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
            <span style="display: inline-block;margin-left: 10px;color: #ff4d4d">인플레이베팅 딜레이 축구,농구,야구,배구,하키,기타 총 6종목 순서 `초단위입니다`(최소 1'   최대 15')</span>
          </div>
          <div class="sitem">
            <label style="width: 150px">본인,추천인 낙첨포인트</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.lowodds"></el-input>
            배당 이하는 폴더수에서 제외,
            <el-input size="mini" style="width: 100px;margin-left: 5px" v-model="siteConfig.eventgamecount"></el-input>
            폴더 이상부터지급
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 150px"> 베팅취소 </label>
            베팅후
            <el-input size="mini" style="width: 100px" v-model="siteConfig.betCancelBeforTime"></el-input>
            분 이내 취소가능,
            경기시작
            <el-input size="mini" style="width: 100px;margin-left: 5px"
                      v-model="siteConfig.betCancelGamestartBeforTime"></el-input>
            분 전 취소가능
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <!--                    <div class="sitem">-->
          <!--                        <label style="width: 80px">다폴더 보너스 배당:</label>-->
          <!--                        2폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf2"></el-input>,-->
          <!--                        3폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf3"></el-input>,-->
          <!--                        4폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf4"></el-input>,-->
          <!--                        5폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf5"></el-input>,-->
          <!--                        6폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf6"></el-input>,-->
          <!--                        7폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf7"></el-input>,-->
          <!--                        8폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf8"></el-input>,-->
          <!--                        9폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf9"></el-input>,-->
          <!--                        10폴더<el-input size="mini" style="width: 70px" v-model="siteConfig.bf10"></el-input>-->
          <!--                        <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트-->
          <!--                        </el-button>-->
          <!--                    </div>-->

          <div class="sitem">
            <label style="width: 150px"> 스포츠 베팅 </label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.waringBetCash"></el-input>
            원 초과시 경고알림
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 150px"> Inplay 베팅 </label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.warningInplayBetCash"></el-input>
            원 초과시 경고알림
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 150px"> 미니게임 베팅 </label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.warningLeisureBetCash"></el-input>
            원 초과시 경고알림
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

        </el-collapse-item>
        <el-collapse-item title=" ➽ 경기 설정" name="경기관련 설정">
          <div class="sitem">
            <label style="width: 130px">스포츠`경기`등록</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.sportsInsertAutoable"
                       placeholder="스포츠">
              <el-option :value="managerConst.ENABLE" label="자동">자동
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="수동">수동
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">스포츠`결과`등록</label>
            <el-select size="mini" style="width: 130px;"
                       v-model="siteConfig.sportsResultAutoable"
                       placeholder="스포츠">
              <el-option :value="managerConst.ENABLE" label="자동">자동
              </el-option>
              <el-option :value="managerConst.DISABLE"
                         label="수동">수동
              </el-option>
            </el-select>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <!--                    <div class="sitem">-->
          <!--                        <label style="width: 130px">스포츠 자동등록시</label>-->
          <!--                        `풀타임` 경기를-->
          <!--                        <el-select size="mini" style="width: 130px;"-->
          <!--                                   v-model="siteConfig.sportsautostatus"-->
          <!--                                   placeholder="스포츠">-->
          <!--                            <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">`베팅대기`-->
          <!--                            </el-option>-->
          <!--                            <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">`베팅가능`-->
          <!--                            </el-option>-->
          <!--                        </el-select>-->
          <!--                        상태로 등록-->
          <!--                        <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트-->
          <!--                        </el-button>-->
          <!--                    </div>-->
          <!--                    <div class="sitem">-->
          <!--                        <label style="width: 130px">스포츠 자동등록시</label>-->
          <!--                        `하프타임` 경기를-->
          <!--                        <el-select size="mini" style="width: 130px;"-->
          <!--                                   v-model="siteConfig.halfsportsautostatus"-->
          <!--                                   placeholder="스포츠">-->
          <!--                            <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">`베팅대기`-->
          <!--                            </el-option>-->
          <!--                            <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">`베팅가능`-->
          <!--                            </el-option>-->
          <!--                        </el-select>-->
          <!--                        상태로 등록-->
          <!--                        <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트-->
          <!--                        </el-button>-->
          <!--                    </div>-->

          <div class="sitem">
            <label style="width: 150px"> 스포츠 베팅마감 </label>
            경기시작
            <el-input size="mini" style="width: 100px" v-model="siteConfig.protoBetStopBeforTime"></el-input>
            `초` 전
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

          <div class="sitem">
            <label style="width: 150px"> 스포츠경기 </label>
            베팅내역이 없는
            <el-input size="mini" style="width: 70px" v-model="siteConfig.protoBetresultRetentionDay"></el-input>
            `일` 전 경기는 삭제 됩니다.
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 150px"> 미니게임 </label>
            베팅내역이 없는
            <el-input size="mini" style="width: 70px" v-model="siteConfig.minigameBetresultRetentionDay"></el-input>
            `일` 전 경기는 삭제 됩니다.
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

        </el-collapse-item>
        <el-collapse-item title=" ➽ 이미지서버 설정" name="이미지서버 설정">
          <div class="sitem">
            <label style="width: 130px">이미지 서버</label>
            <el-input size="mini" style="width: 200px" v-model="siteConfig.imageServer"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">이미지 업로드 서버</label>
            <el-input size="mini" style="width: 200px" v-model="siteConfig.imageUploadServer"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item title=" ➽ 쿠폰 설정" name="쿠폰 설정">
          <div class="sitem">
            <label style="width: 130px">실버쿠폰 10%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupons10"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">실버쿠폰 20%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupons20"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px">실버쿠폰 70%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupons70"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px;color: goldenrod">골드쿠폰 10%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupong10"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px;color: goldenrod">골드쿠폰 20%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupong20"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px;color: goldenrod">골드쿠폰 70%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupong70"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px;color: blue">다이아쿠폰 10%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupond10"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px;color: blue">다이아쿠폰 20%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupond20"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem">
            <label style="width: 130px;color: blue">다이아쿠폰 70%확율 당첨금</label>
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coupond70"></el-input>
            원
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>

        <el-collapse-item title=" ➽ 이벤트 설정" name="이벤트 설정">
          <div class="sitem text-blue">
            <div>굿모닝 이벤트 설정 (최종입금성공금액기준)</div>
          </div>
          <div class="sitem text-blue">
            <span class="text-red"> *지급설정 예제)</span> 5-3000,10-7000,30-25000
            >> 5만에3천원,10만에7천원,30만에2만5천 지급
            >> 7만원입금시 5만원기준으로 드림
            >> 지급을 중단하려면 5-0,10-0,30-0 으로 설정가능
          </div>
          <div class="sitem">
            <el-input size="mini" style="width: 100px" v-model="siteConfig.owleventstarthour"></el-input>
            시 ~
            <el-input size="mini" style="width: 100px" v-model="siteConfig.owleventendhour"></el-input>
            시 까지 진행,
          </div>
          <div class="sitem">
            <label style="width: 70px" class="text-green">지급설정</label>
            <el-input size="mini" style="width: 580px" v-model="siteConfig.owleventstringsetting"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
          <div class="sitem text-blue">
            <div>깜작 이벤트 설정 (최종입금성공금액기준)</div>
          </div>
          <div class="sitem text-blue">
            <span class="text-red"> *지급설정 예제)</span> 5-3000,10-7000,30-25000
            >> 5만에3천원,10만에7천원,30만에2만5천 지급
            >> 7만원입금시 5만원기준으로 드림
            >> 지급을 중단하려면 5-0,10-0,30-0 으로 설정가능
          </div>
          <div class="sitem">
            1시간대
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coffeeventstarthour1"></el-input>
            시 ~
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coffeeventendhour1"></el-input>
            시 까지 진행,
            2시간대
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coffeeventstarthour2"></el-input>
            시 ~
            <el-input size="mini" style="width: 100px" v-model="siteConfig.coffeeventendhour2"></el-input>
            시 까지 진행,
          </div>
          <div class="sitem">
            <label style="width: 70px" class="text-green">지급설정</label>
            <el-input size="mini" style="width: 580px" v-model="siteConfig.coffeeventstringsetting"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

        </el-collapse-item>

        <el-collapse-item title=" ➽ 점검문구 설정" name="점검문구 설정">
          <div class="sitem">
            <label style="width: 130px">점검문구</label>
            <div class="editor">
              <quill-editor ref="myQuillEditor" v-model="siteConfig.content"
                            :options="editorOption"
                            @change="onEditorChange($event)"/>
            </div>

            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

        </el-collapse-item>

        <el-collapse-item title=" ➽ 회원가입시 쪽지문구 설정" name="회원가입시 쪽지문구 설정">
          <div class="sitem">
            <label style="width: 130px">점검문구</label>
            <div class="editor">
              <quill-editor ref="myQuillEditor" v-model="siteConfig.regmessage"
                            :options="editorOption"
                            @change="onEditorChange2($event)"/>
            </div>

            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>

        </el-collapse-item>

        <el-collapse-item title=" ➽ 입금페이지 입금안내 문구 설정" name="입금페이지 입금안내 문구 설정">
          <div class="sitem">
            <label style="width: 130px">입금안내 문구</label>
            <div class="editor">
              <quill-editor ref="myQuillEditor" v-model="siteConfig.rechargeinfotext"
                            :options="editorOption"
                            @change="onEditorChange3($event)"/>
            </div>

            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>

        <el-collapse-item title=" ➽ 출금페이지 출금안내 문구 설정" name="출금페이지 출금안내 문구 설정">
          <div class="sitem">
            <label style="width: 130px">출금안내 문구</label>
            <div class="editor">
              <quill-editor ref="myQuillEditor" v-model="siteConfig.exchangeinfotext"
                            :options="editorOption"
                            @change="onEditorChange4($event)"/>
            </div>

            <el-button size="mini" style="margin-left: 5px" type="primary" @click="updateSiteConfig">업데이트
            </el-button>
          </div>
        </el-collapse-item>


      </el-collapse>
    </div>
  </div>

</template>

<script>

import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
import {copyGroup, deleteGroup, getGroupList, updateGroup} from "../../network/manager/groupRequest";
import managerConst from "../../common/administrator/managerConst";
import {manager} from "../../common/administrator/managerMixin";
import {Loading} from "element-ui";
import {
  copyRank,
  deleteRank,
  getRankList,
  updateLesureBetConfig,
  updateRank
} from "../../network/manager/rankRequest";
import {uploadFile} from "../../network/manager/commonRequest";
import {
  findsiteCommonConfigOnly,
  updateCommonConfig,
  updateSiteAndOwnConfig
} from "../../network/manager/siteConfigRequest";

export default {
  name: "ManagerSiteConfig",
  mixins: [manager],
  components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
  data() {
    return {
      activeNames: '서버상태 설정',
      siteConfig: {},
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'],         //引用，代码块
              [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
              [{'list': 'ordered'}, {'list': 'bullet'}],          //列表
              [{'script': 'sub'}, {'script': 'super'}],      // 上下标
              [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
              [{'direction': 'rtl'}],                         // 文本方向
              [{'size': ['small', false, 'large', 'huge']}],  // 字体大小
              [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题
              [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
              [{'font': []}],         //字体
              [{'align': []}],        //对齐方式
              ['clean'],        //清除字体样式
            ]
          }
        },

      },
    }
  },
  methods: {
    getSiteConfig() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      findsiteCommonConfigOnly().then(res => {
        this.siteConfig = res.data.data
        loadingInstance.close()
      })
    },
    updateSiteConfig() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      updateCommonConfig(this.siteConfig).then(res => {
        loadingInstance.close()
        if (res.data.success) {
          this.$message({
            type: 'success',
            duration: 1000,
            message: '업데이트 완료되였습니다'
          });
          this.getSiteConfig()
        } else {
          this.$message({
            showClose: true,
            duration: 3000,
            message: res.data.msg,
            type: 'error'
          });
        }

      })
    },
    onEditorChange(editor) {
      this.siteConfig.content = editor.html;
    },
    onEditorChange2(editor) {
      this.siteConfig.regmessage = editor.html;
    },
    onEditorChange3(editor) {
      this.siteConfig.rechargeinfotext = editor.html;
    },
    onEditorChange4(editor) {
      this.siteConfig.exchangeinfotext = editor.html;
    },
  },
  created() {
    this.getSiteConfig()
  },
  watch: {}
}
</script>

<style scoped>

.sitem {
  width: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  background-color: #cfcfd1;
  justify-content: flex-start;
  align-items: center;
}
::v-deep .ql-editor {
  background-color: #bdbdbd;
}


</style>